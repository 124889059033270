
import { defineComponent, PropType } from 'vue';
import { NButton, NDataTable } from "naive-ui";
import HeaderContent from "@/components/template/HeaderContent.vue";
import animalService from "@/services/animal.service";
import { dateTimeFilter } from "../filters/custom-filters";
import { h } from "@vue/runtime-core";
import router from "@/router";
import GlobalEvent from "@/common/global-event";
import { Animal } from '@/models/animal/animal.model';

const createColumns = () => {
  return [
    {
      title: "Data Ultima Atualização",
      key: "data?.dataUltimaAtualizacao",
      sorter: "default",
      defaultSortOrder: "descend",
      render(row: any) {
        return dateTimeFilter(row.data?.dataUltimaAtualizacao);
      },
    },
    {
      title: "Ações",
      key: "acoes",
      render(item: any) {
        return [
          h(
            NButton,
            {
              size: "small",
              onClick: () =>
                router.push({
                  name: "historyAnimalDetail",
                  params: {
                    idAnimal: item.data?.idBlockchain,
                    animal: JSON.stringify(item.data),
                    titlePage: `Histórico do Animal em ${dateTimeFilter(
                      item.data?.dataUltimaAtualizacao
                    )}`,
                  },
                }),
              text: true,
              class: "me-2",
              title: "Visualizar animal",
            },
            h("i", {
              class: "bi bi-search p-1 safe-icon-yellow",
            })
          ),
        ];
      },
    },
  ];
};

const HistoryAnimal = defineComponent({
  components: { HeaderContent, NDataTable },

  props: {
    idAnimal: {
      type: String,
      default: () => ''
    }
  },

  data() {
    return {
      historyAnimalList: new Array<Animal>(),
      columns: createColumns()
    };
  },

  methods: {
    dateTimeFilter,

    getHistoryAnimal() {
      GlobalEvent.emit("appLoading", true);
      animalService
        .getHistoryAnimalById(this.idAnimal)
        .then((res) => {
          this.historyAnimalList = res?.data;
        })
        .finally(() => GlobalEvent.emit("appLoading", false));
    },
  },

  mounted() {
    this.getHistoryAnimal();
  },
})
export default HistoryAnimal;
