
import GlobalEvent from "@/common/global-event";
import { defineComponent } from "@vue/runtime-core";

const HeaderContent = defineComponent({
  props: {
    title: {
      type: String,
    },
  },
  methods: {
    sideBarToggle() {
      GlobalEvent.emit("sidebar");
    }
  }
})
export default HeaderContent;
