import { Animal } from "@/models/animal/animal.model";
import http from "../common/http-common";
import { Environment } from "@/config/config";
class AnimalService {
  getAnimalsByIdProducer(id: string, queryType = '', query = '', pageSize: number = Environment.APP_PROPERTIES.PAGE_SIZE, bookmark: string = ''): Promise<any> {
    const url = "/v1/admin/animal";
    const urlsParams = new URLSearchParams({
      idProdutor: id,
      pageSize: pageSize.toString(),
      bookmark,
      queryType,
      query
    });

    return http.get(`${url}?${urlsParams}`);
  }

  getAnimalById(id: string): Promise<any> {
    return http.get(`/v1/admin/animal/${id}`);
  }

  getHistoryAnimalById(id: string): Promise<any> {
    return http.get(`/v1/admin/animal/history/${id}`);
  }
}

export default new AnimalService();